import React from "react"
import Modal from "react-bootstrap/Modal"
import DownloadBrochureForm from "../forms/DownloadBrochureForm"
import "./EnquireModal.scss"

const BrochureModal = props => {
  if (!props.file_url) return null

  return (
    <div className="enquire-wrapper">
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="enquire-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3 className="title">Download Brochure</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DownloadBrochureForm file_url={props.file_url}/>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default BrochureModal
